import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import Cashdown from '../common/cashdown/Cashdown'
import PaymentFrequency from '../common/payment-frequency/PaymentFrequency'
import PercentRate from '../common/percent-rate/PercentRate'
import FeesDisclaimer from '../common/fees-disclaimer/FeesDisclaimer'
import TaxesDisclaimer from '../common/taxes-disclaimer/TaxesDisclaimer'
import Incentives from '../common/incentives/Incentives'
import Term from '../common/term/Term'
import TotalCreditCost from '../common/total-credit-cost/TotalCreditCost'
import VehicleRebates from '../../vehicle-rebates/VehicleRebates'

const FinanceCard = ({
    finance,
    disclaimer,
    extraClasses,
    showIncentives,
    lang,
    showTotalCreditCost,
    showVehicleRebate,
    displayDisclaimerSymbols,
}) => {
    const { term, paymentFrequency, cashDown, incentives, taxesDisclaimer } = finance

    const baseClass = 'vc-financeCard'
    const classNames = clsx(baseClass, extraClasses)

    return (
        <div className={classNames}>
            <div className={`${baseClass}__price`}>
                <div className={`${baseClass}__frequencyTerm`}>
                    <PaymentFrequency
                        termPayment={term.payment}
                        frequency={paymentFrequency}
                        displayDisclaimerSymbols={displayDisclaimerSymbols}
                    />
                    <Term term={term?.term} />
                </div>
                <div className={`${baseClass}__rateCashDown`}>
                    <PercentRate rate={term.apr} term={term.term} />
                    <Cashdown cashDown={cashDown} />
                </div>
            </div>
            {showTotalCreditCost && (
                <TotalCreditCost
                    extraClasses={`${baseClass}__creditCost`}
                    totalCreditCost={term?.aprDetails?.totalCreditCost}
                    lang={lang}
                />
            )}
            {showVehicleRebate && <VehicleRebates extraClasses={`${baseClass}__rebates`} />}
            {showIncentives && <Incentives extraClasses={`${baseClass}__incentives`} incentives={incentives} />}
            <TaxesDisclaimer extraClasses={`${baseClass}__taxesDisclaimer`} taxesDisclaimer={taxesDisclaimer} />
            <FeesDisclaimer extraClasses={`${baseClass}__feesDisclaimer`} feesDisclaimer={disclaimer} />
        </div>
    )
}

FinanceCard.propTypes = {
    /** Finance object */
    finance: PropTypes.object,
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Disclaimer */
    disclaimer: PropTypes.string,
    /** Show Incentives */
    showIncentives: PropTypes.bool,
    /** Selected language */
    lang: PropTypes.string,
    /** Flag to show or hide Total credit cost */
    showTotalCreditCost: PropTypes.bool,
    /** Flag to show or hide Vehicle Rebate */
    showVehicleRebate: PropTypes.bool,
    /** Flag to show or hide disclaimer symbols */
    displayDisclaimerSymbols: PropTypes.bool,
}

FinanceCard.defaultProps = {
    finance: {},
    extraClasses: '',
    disclaimer: '',
    showIncentives: true,
    lang: 'en',
    showTotalCreditCost: false,
    showVehicleRebate: false,
    displayDisclaimerSymbols: false,
}

export default FinanceCard
