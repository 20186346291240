import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Icon } from '@sm360/ui-library'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const VehicleStatus = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            XMSConfig: { statusConfigurations },
            vehicle: { vehicleStatus, onHold, stickerStatusInTransit, stickerStatusOnOrder },
            isHumberview,
        },
    } = React.useContext(VehicleCardContext)

    const vehicleIsAvailable = vehicleStatus !== 'SOLD' && vehicleStatus !== 'DELIVERED' && vehicleStatus !== 'ON_HOLD' && !onHold

    const statusInfo = (statusType) => {
        const status = statusConfigurations.filter(({ statusDefinition }) => statusDefinition.code === statusType)[0]

        const { label, layout, picto, backgroundColor, fontColor } = status || {}

        const baseClass = 'vc-vehicleStatus'
        const classNames = clsx(baseClass, layout && isHumberview ? `-${layout}` : '-custom', extraClasses)

        if (layout === '') return null

        return (
            <div
                className={classNames}
                {...extraParams}
                style={{
                    backgroundColor,
                    color: fontColor,
                }}
            >
                {picto && <Icon name={picto} className={`${baseClass}__icon`} />}

                <span className={`${baseClass}__label`}>{label}</span>
            </div>
        )
    }

    if (vehicleIsAvailable && stickerStatusInTransit) return statusInfo('in_transit')
    if (vehicleIsAvailable && stickerStatusOnOrder) return statusInfo('on_order')
    if (!vehicleIsAvailable && vehicleStatus === 'SOLD') return statusInfo('sold')
    if (!vehicleIsAvailable && onHold) return statusInfo('on_hold')
    if (!vehicleIsAvailable && vehicleStatus === 'DELIVERED') return statusInfo('delivered')

    return null
}

VehicleStatus.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

VehicleStatus.defaultProps = {
    extraClasses: '',
}

export default VehicleStatus
