import React from 'react'

const initialState = {
    isFetching: false,
    vehicle: {
        paymentOptions: {},
        multimedia: {},
    },
    XMSConfig: {
        configuration: {},
        conditionsConfigurations: [],
        highlightConfigurations: [],
        inventoryNewCompareConfiguration: {},
        inventoryUsedCompareConfiguration: {},
        pictureConfiguration: {},
        statusConfigurations: [],
        taggingConfigurations: [],
    },
    dealerInfos: {
        dealerMakes: [],
        domain: '',
    },
    vehicleCtas: [],
    isInitialFetched: false,
    isHumberview: true,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetching': {
            return {
                ...state,
                isFetching: true,
            }
        }
        case 'fetchSucceed': {
            return {
                ...state,
                isFetching: false,
            }
        }
        case 'init': {
            return {
                ...state,
                vehicle: action.vehicle,
                dealerInfos: action.dealerInfos,
                XMSConfig: action.XMSConfig,
                vehicleCtas: action.vehicleCtas,
                isInitialFetched: action.isInitialFetched,
                isHumberview: action.isHumberview,
            }
        }
        case 'updateVehicle': {
            return {
                ...state,
                vehicle: action.vehicle,
            }
        }
        default:
            return state
    }
}

const VehicleCardContext = React.createContext(initialState)

const VehicleCardProvider = ({ XMSConfig, vehicle, children, dealerInfos, vehicleCtas, isHumberview }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    React.useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: 'fetching' })

            dispatch({
                type: 'init',
                vehicle,
                dealerInfos,
                XMSConfig,
                vehicleCtas,
                isHumberview,
            })

            dispatch({ type: 'fetchSucceed' })
        }
        fetchData()
    }, [])

    if (!state.isInitialFetched) {
        dispatch({ type: 'fetching' })

        dispatch({
            type: 'init',
            vehicle,
            dealerInfos,
            XMSConfig,
            vehicleCtas,
            isInitialFetched: true,
            isHumberview,
        })

        dispatch({ type: 'fetchSucceed' })
    }

    React.useEffect(() => {
        const fetchVehicleData = async () => {
            dispatch({ type: 'fetching' })

            dispatch({
                type: 'updateVehicle',
                vehicle,
            })

            dispatch({ type: 'fetchSucceed' })
        }
        fetchVehicleData()
    }, [vehicle]) // Vehicle dependency to update the vehicle card on vehicle obj change

    return <VehicleCardContext.Provider value={{ state, dispatch }}>{children}</VehicleCardContext.Provider>
}

export { VehicleCardContext, VehicleCardProvider }
