import { Icon, Img } from '@sm360/ui-library'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'
import { ListingContext } from '../../../../configs/listing-view/state.manager'
import { getImageUrl } from '../../../../utils/image-url-generator.utils'

const VehicleImage = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            XMSConfig: {
                pictureConfiguration: { newVehiclePlaceholder, usedVehiclePlaceholder },
            },
            vehicle: { newVehicle, multimedia },
        },
    } = React.useContext(VehicleCardContext)

    const {
        state: { isHumberview },
    } = React.useContext(ListingContext)

    const baseClass = 'vc-vehicleImage'
    const classNames = clsx(baseClass, extraClasses)

    const noPhoto = newVehicle ? newVehiclePlaceholder : usedVehiclePlaceholder

    return (
        <div className={classNames}>
            <Img
                className={`${baseClass}__image`}
                {...extraParams}
                src={multimedia?.mainPictureCompleteUrl || getImageUrl(noPhoto, 'w400h300c', 'web')}
                width='400'
                height='300'
            />

            <div className={`${baseClass}__overlays`}>
                {!isHumberview && <Icon name='icon-view-more' className={`${baseClass}__overlaysIcon`} />}
            </div>
        </div>
    )
}

VehicleImage.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

VehicleImage.defaultProps = {
    extraClasses: '',
}

export default VehicleImage
