import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Checkbox } from '@sm360/ui-library'
import { FILTER_OPTIONS_TYPES } from '../../constants/filterOptions'
import { ListingContext } from '../../configs/listing-view/state.manager'
import InputRange from './input-range/InputRange'

// Should update the range label as we add more filter
// Used for minPrice, maxPrice, minOdometer, maxOdometer ...
const filterRangeLabel = {
    priceRange: 'Price',
    yearRange: 'Year',
    odometerRange: 'Odometer',
    priceRangeAll: 'Price',
    yearRangeAll: 'Year',
    odometerRangeAll: 'Odometer',
}

const FilterOption = ({
    label,
    count,
    type,
    extraClasses,
    isSelected,
    vehicleCriteria,
    handleChange,
    handleInputRange,
    min,
    max,
    filterName,
    displayCount,
}) => {
    const {
        state: { isFetchingFilters },
    } = React.useContext(ListingContext)
    const baseClass = 'il-filterOption'
    const classNames = clsx(baseClass, extraClasses)

    if (count === 0 && type !== 'range') return null

    return (
        <div className={`${classNames} ${isFetchingFilters ? '-fetching' : ''}`}>
            {type === FILTER_OPTIONS_TYPES.CHECKBOX && (
                <div className={`${baseClass}__container`}>
                    <div className={`${baseClass}__label`}>
                        <Checkbox
                            label={label}
                            onClick={() => handleChange(label)}
                            checked={isSelected}
                            disabled={isFetchingFilters}
                        />
                    </div>
                    {displayCount && <span className={`${baseClass}__count`}>{count}</span>}
                </div>
            )}
            {type === FILTER_OPTIONS_TYPES.RANGE && (
                <div className={`${baseClass}__container`}>
                    <div className={`${baseClass}__label`}>
                        <InputRange
                            handleInputRange={(name, value) => handleInputRange(name, value)}
                            vehicleCriteria={vehicleCriteria}
                            minInput={`min${filterRangeLabel[filterName]}`}
                            maxInput={`max${filterRangeLabel[filterName]}`}
                            min={min}
                            max={max}
                            disabled={isFetchingFilters}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

FilterOption.propTypes = {
    /** Filter option label */
    label: PropTypes.string,
    /** Vehicle count */
    count: PropTypes.number,
    /** Filter type */
    type: PropTypes.oneOf([FILTER_OPTIONS_TYPES.CHECKBOX, FILTER_OPTIONS_TYPES.RANGE]),
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Flag to set if filter is selected or not */
    isSelected: PropTypes.bool,
    /** Selected vehicle criteria obj */
    vehicleCriteria: PropTypes.object,
    /** Change function mainly for filters except input range */
    handleChange: PropTypes.func,
    /** Change function only for Input range */
    handleInputRange: PropTypes.func,
    /** Number value for input range minimum */
    min: PropTypes.number,
    /** Number value for input range maximum */
    max: PropTypes.number,
    /** Filter name */
    filterName: PropTypes.string,
    /** Boolean to indicate if display count or not */
    displayCount: PropTypes.bool,
}

FilterOption.defaultProps = {
    label: '',
    count: 0,
    type: FILTER_OPTIONS_TYPES.CHECKBOX,
    extraClasses: '',
    isSelected: false,
    vehicleCriteria: {},
    min: 0,
    max: 0,
    displayCount: false,
}

export default FilterOption
