import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useTranslation } from '@sm360/hooks'

import { Tabs } from '@sm360/ui-library'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

import { isEmptyObject } from '../../../../utils/helper.utils'
import CashCard from './cash-card/CashCard'
import FinanceCard from './finance-card/FinanceCard'
import LeaseCard from './lease-card/LeaseCard'

const PaymentOptions = ({ purchaseMethod, extraClasses }) => {
    const {
        state: {
            vehicle: {
                newVehicle,
                paymentOptions,
                paymentOptions: { cashPurchase, finance, lease, disclaimer },
            },
            dealerInfos: { lang },
            isHumberview,
        },
    } = React.useContext(VehicleCardContext)

    const { t } = useTranslation()

    const baseClass = 'vc-paymentOptions'
    const classNames = clsx(baseClass, extraClasses)

    const tabsClassNames = {
        className: classNames,
        selectedTabClassName: `${baseClass}__tabSelected`,
        disabledTabClassName: `${baseClass}__tabDisabled`,
        selectedTabPanelClassName: `${baseClass}__panelSelected`,
    }

    let activeTag = 0

    if (purchaseMethod === 'finance' && finance) {
        activeTag = 1
    } else if (purchaseMethod === 'lease' && lease) {
        activeTag = 2
    } else {
        activeTag = 0
    }

    if (isEmptyObject(paymentOptions)) return null

    return (
        <Tabs activeIndex={activeTag} {...tabsClassNames}>
            <div trigger={t('tmb.tabTitle.cash')}>
                <CashCard
                    cashPurchase={cashPurchase}
                    showIncentives={newVehicle && isHumberview}
                    showRetailsTotal={newVehicle}
                    disclaimer={disclaimer}
                    showVehicleRebate={!isHumberview}
                    displayDisclaimerSymbols={!isHumberview}
                />
            </div>

            <div trigger={t('tmb.tabTitle.finance')} disabled={!finance}>
                <FinanceCard
                    finance={finance}
                    disclaimer={disclaimer}
                    showIncentives={newVehicle && isHumberview}
                    lang={lang}
                    showTotalCreditCost={!isHumberview}
                    showVehicleRebate={!isHumberview}
                    displayDisclaimerSymbols={!isHumberview}
                />
            </div>

            <div trigger={t('tmb.tabTitle.lease')} disabled={!lease}>
                <LeaseCard
                    lease={lease}
                    disclaimer={disclaimer}
                    showIncentives={isHumberview}
                    lang={lang}
                    showTotalCreditCost={!isHumberview}
                    showVehicleRebate={!isHumberview}
                    displayDisclaimerSymbols={!isHumberview}
                />
            </div>
        </Tabs>
    )
}

PaymentOptions.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
    purchaseMethod: PropTypes.string,
}

PaymentOptions.defaultProps = {
    extraClasses: '',
    purchaseMethod: '',
}

export default PaymentOptions
