import { apiUrlEnv, buildUrlWithParameters } from '@sm360/helpers'
import axios from 'axios'

/**
 * Get Widget TradeIn 360
 * @param { String } customId - Element id
 * @param { Object } dealerInfos - Dealer infos
 * @param { Boolean } isLocal - Is Local
 * @param { String } packageVersion - Package Version (basic / premium)
 * @returns {Promise<{}>}
 */
export const getWidgetTradeIn360APIUrl = async (customId, dealerInfos, isLocal, packageVersion) => {
    const apiUrl = `${apiUrlEnv('REACT_APP_WIDGET_LOADER_API_URL')}/api/widgets/trade-in-360/InstantEstimateProvider/render`

    const { orgUnitProvince, websiteId, orgUnitId, orgId, lang } = dealerInfos

    let paramsUrl = {
        location: 'string(standalone.tradein.responsive)',
        locale: `string(${lang}-CA)`,
        websiteId: `int(${websiteId})`,
        organizationUnitLocation: `string(${orgUnitProvince})`,
        organizationUnitId: `int(${orgUnitId})`,
        organizationId: `int(${orgId})`,
        loadCommons: `${isLocal ? 'bool(true)' : 'bool(false)'}`,
        deferScripts: 'bool(true)',
        isShowroomV25Enabled: 'bool(true)',
        isShowroomV2Enabled: 'bool(true)',
        isShowroom360: 'bool(true)',
        isModal: 'bool(true)',
        onlyWidgetHTML: 'bool(false)',
        parentElementId: `string(${customId})`,
    }

    // Change params for Basic Version
    if (packageVersion === 'basic') {
        paramsUrl = {
            ...paramsUrl,
            isShowroomV25Enabled: 'bool(false)',
            isShowroomV2Enabled: 'bool(false)',
            isShowroom360: 'bool(false)',
        }
    }

    const urlWithParams = buildUrlWithParameters(apiUrl, paramsUrl)

    let apiResponse = {}

    try {
        const response = await axios.get(urlWithParams)
        if (response) {
            apiResponse = await response.data
        }
        return apiResponse
    } catch (error) {
        return apiResponse
    }
}
