import { WindowProxy } from '@sm360/helpers'
import { useDeviceType } from '@sm360/hooks'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { ListingContext } from '../../configs/listing-view/state.manager'
import { alignCardItems } from '../../utils/global-functions.utils'
import VehicleCardViewProvider from '../../views/vehicle-card/VehicleCard.provider'
import VehiclesSkeleton from './Vehicles.skeleton'

const Vehicles = ({ extraClasses, packageVersion }) => {
    const {
        state: { vehicles, XMSConfig, isFetching, dealerInfos, vehicleCtas, promoCards, isHumberview },
    } = React.useContext(ListingContext)

    const { device } = useDeviceType()

    const cardItemsRef = React.createRef()

    const [isCalculatorModalOpen, setIsCalculatorModalOpen] = React.useState(false)

    const baseClass = 'il-vehicles'
    const classNames = clsx(baseClass, extraClasses)

    const version = packageVersion === 'premium' ? 'bravo' : 'alpha'

    React.useEffect(() => {
        const vehiclesParent = cardItemsRef?.current
        if (vehiclesParent) {
            if (version === 'bravo' && device === 'desktop') {
                setTimeout(() => {
                    alignCardItems(vehiclesParent.querySelectorAll('.s360-o-vehicleCardBravo'), [
                        '.s360-o-vehicleCardBravo__header',
                        //     '.s360-o-vehicleCardBravo__conditionAndHighlightInfos',
                        '.s360-o-vehicleCardBravo__infos',
                        '.s360-o-vehicleCardBravo__vehiclePaymentOptions',
                        '.s360-o-vehicleCardBravo__vehicleOptionsAndAccessories',
                        //     // '.s360-o-vehicleCardBravo__dealerName',
                        //     '.s360-o-vehicleCardBravo__vehicleCardSpecs',
                    ])
                }, 500)
            } else {
                alignCardItems(vehiclesParent.querySelectorAll('.vc-alpha'), [
                    '.vc-alpha__header',
                    '.vc-alpha__infos',
                    '.vc-alpha__specs',
                    '.vc-alpha__highlights',
                    '.vc-alpha__tagline',
                    '.vc-alpha__carfaxBadges',
                    '.vc-alpha__certifiedConditions',
                    '.vc-alpha__placeholder',
                ])
            }
        }
    }, [vehicles])

    React.useEffect(() => {
        if (WindowProxy) {
            WindowProxy.document.addEventListener('sm360.calculator.vlp.modal.open', () => onCalculatorModalOpen(true))
            WindowProxy.document.addEventListener('sm360.calculator.vlp.modal.close', () => onCalculatorModalOpen(false))
        }
    }, [])

    const onCalculatorModalOpen = (isOpen) => {
        setIsCalculatorModalOpen(isOpen)
    }

    if (isFetching) return <VehiclesSkeleton extraClasses={extraClasses} />

    return (
        <div ref={cardItemsRef} className={classNames}>
            {vehicles?.map((vehicle, index) => (
                <VehicleCardViewProvider
                    key={index}
                    vehicle={vehicle}
                    XMSConfig={XMSConfig}
                    dealerInfos={dealerInfos}
                    vehicleCtas={vehicleCtas}
                    packageVersion={packageVersion}
                    isCalculatorModalOpen={isCalculatorModalOpen}
                    promoCards={promoCards}
                    isHumberview={isHumberview}
                />
            ))}
        </div>
    )
}

Vehicles.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
    /** Package version to display */
    packageVersion: PropTypes.string,
}

Vehicles.defaultProps = {
    extraClasses: '',
    packageVersion: '',
}

export default Vehicles
