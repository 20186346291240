import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useTranslation } from '@sm360/hooks'
import { DisclaimerSymbols } from '@sm360/components'
import formatNumber from '../../../../../../utils/format-number.utils'

const PaymentFrequency = ({ termPayment, frequency, displayDisclaimerSymbols, extraClasses }) => {
    const { t } = useTranslation()

    const baseClass = 'vc-paymentFrequency'
    const classNames = clsx(baseClass, extraClasses)

    return (
        <div className={classNames}>
            <span className={`${baseClass}__price`}>{formatNumber(termPayment, 'priceNoDigitsCeil')}</span>
            {displayDisclaimerSymbols && <DisclaimerSymbols extraClasses={`${baseClass}__disclaimerSymbols`} />}
            <span className={`${baseClass}__frequency`}>{t(`paymentOptions.frequency.${frequency}`)}</span>
        </div>
    )
}

PaymentFrequency.propTypes = {
    /** Term Payment by frequency */
    termPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Frequency of payments */
    frequency: PropTypes.oneOf([12, 24, 26, 52, '12', '24', '26', '52']).isRequired,
    /** Fees Disclaimer */
    feesDisclaimer: PropTypes.string,
    /** Flag to show or hide disclaimer symbols */
    displayDisclaimerSymbols: PropTypes.bool,
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

PaymentFrequency.defaultProps = {
    extraClasses: '',
    termPayment: '',
    frequency: '52',
    feesDisclaimer: '',
    displayDisclaimerSymbols: false,
}

export default PaymentFrequency
