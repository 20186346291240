import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useTranslation } from '@sm360/hooks'
import formatNumber from '../../../../../../utils/format-number.utils'

const TotalCreditCost = ({ lang, totalCreditCost, extraClasses }) => {
    const baseClass = 'vc-totalCreditCost'
    const classNames = clsx(baseClass, extraClasses)
    const { t } = useTranslation()

    if (!totalCreditCost) return null

    return (
        <p className={classNames}>
            {t('paymentOptions.aprDetails.totalCreditCost.label', {
                totalCreditCost: formatNumber(totalCreditCost, 'priceDigitsAuto', lang),
            })}
        </p>
    )
}

TotalCreditCost.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
    /** Total credit cost */
    totalCreditCost: PropTypes.number,
    /** Selected language */
    lang: PropTypes.string,
}

TotalCreditCost.defaultProps = {
    extraClasses: '',
    lang: 'en',
    totalCreditCost: 0,
}

export default TotalCreditCost
