import PropTypes from 'prop-types'
import React from 'react'

import { VehicleCardProvider } from '../../configs/vehicle-card-view/state.manager'
import VehicleCardView from './VehicleCard.view'

const VehicleCardViewProvider = ({
    XMSConfig,
    vehicle,
    dealerInfos,
    vehicleCtas,
    packageVersion,
    isCalculatorModalOpen,
    promoCards,
    isHumberview,
}) => {
    /* TODO: remove the props orgUnitId when Delta return the version */
    return (
        <VehicleCardProvider
            vehicle={vehicle}
            XMSConfig={XMSConfig}
            dealerInfos={dealerInfos}
            vehicleCtas={vehicleCtas}
            isHumberview={isHumberview}
        >
            <VehicleCardView
                vehicle={vehicle}
                orgUnitId={dealerInfos.orgUnitId}
                domain={dealerInfos.domain}
                packageVersion={packageVersion}
                isCalculatorModalOpen={isCalculatorModalOpen}
                promoCards={promoCards}
            />
        </VehicleCardProvider>
    )
}

VehicleCardViewProvider.propTypes = {
    /** Vehicle object */
    vehicle: PropTypes.object,
    /** Dealer Infos */
    dealerInfos: PropTypes.object,
    /** XMSConfig Infos */
    XMSConfig: PropTypes.object,
    /** Vehicles Ctas */
    vehicleCtas: PropTypes.array,
    /** Package version to display */
    packageVersion: PropTypes.string,
    /** Flag for Calculator Modal */
    isCalculatorModalOpen: PropTypes.bool,
}

VehicleCardViewProvider.defaultProps = {
    vehicle: {},
    XMSConfig: {},
    dealerInfos: {},
    vehicleCtas: [],
    packageVersion: '',
}

export default VehicleCardViewProvider
